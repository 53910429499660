<template lang="pug">
  div
    v-simple-table
      template(v-slot:default)
        thead
          tr
            th Stock
            th Qty
            th Unit
            th Cost
        tbody
          tr(v-for="(item, index) in items" :key="index")
            td {{ item.stockId }}
            td {{ item.quantity }}
            td {{ item.unit }}
            td.text-right {{ item.cost.phpFormat() }}
          tr
            th(colspan="3") Total
            td.text-right {{ totalAmount.phpFormat() }}
</template>
<script>
export default {
  name: 'TablePurchaseRequestDetail',
  props: ['items'],
  computed: {
    totalAmount () {
      return this.items.reduce((t, c) => t + c.cost, 0)
    },
  },
}
</script>